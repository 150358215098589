import Vue from 'vue'
import {
  LayoutPlugin,
  NavbarPlugin,
  CarouselPlugin,
  AlertPlugin,
  ModalPlugin,
  CardPlugin,
  DropdownPlugin,
} from 'bootstrap-vue'

Vue.use(LayoutPlugin)
Vue.use(NavbarPlugin)
Vue.use(CarouselPlugin)
Vue.use(AlertPlugin)
Vue.use(ModalPlugin)
Vue.use(CardPlugin)
Vue.use(DropdownPlugin)
