<template>
  <b-navbar toggleable="md" type="light" :sticky="true" style="background-color: #ededed">
    <div class="container">
      <b-navbar-brand href="/" style="padding: 10px">
        <!-- <img alt="Brand" src="@/assets/img/logo-siap-egov.png" width="175" height="30" /> -->
      </b-navbar-brand>
      <b-navbar-toggle target="nav_collapse" class="hamburger"></b-navbar-toggle>
      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav class="ml-auto" style="font-weight: 600">
          <b-nav-item href="/" :active="currRoute === 'home'">Beranda</b-nav-item>
          <b-nav-item-dropdown text="Produk" right>
            <b-dropdown-item href="#/dasbordiklat" :active="currRoute === 'dasbordiklat-index'"
              >Dasbor DIO</b-dropdown-item
            >
            <b-dropdown-item href="#/simdiklat" :active="currRoute === 'simdiklat-index'">SIM DIO</b-dropdown-item>
            <b-dropdown-item href="#/lmsdiklat" :active="currRoute === 'lmsdiklat-index'">LMS DIO</b-dropdown-item>
            <b-dropdown-item href="#/personalia" :active="currRoute === 'personalia-index'"
              >Personalia DIO</b-dropdown-item
            >
            <b-dropdown-item href="#/smartmonev" :active="currRoute === 'smartmonev-index'"
              >Smart Monev</b-dropdown-item
            >
            <b-dropdown-item href="#/liveagent" :active="currRoute === 'liveagent-index'">Live Agent</b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- <b-nav-item-dropdown text="Harga" right>
            <b-dropdown-item href="#/personalia/harga"
            :active="currRoute === 'personalia-harga'">Harga SmartHR Personalia</b-dropdown-item>
            <b-dropdown-item href="#/elearning/harga"
            :active="currRoute === 'elearning-harga'">Harga SmartHR E-learning</b-dropdown-item>
            <b-dropdown-item href="#/webconfrence/harga"
            :active="currRoute === 'webconfrence-harga'">Harga SmartHR Web Conference</b-dropdown-item>
            <b-dropdown-item href="#/ppdb/harga"
            :active="currRoute === 'ppdb-harga'">Harga PPDB Online</b-dropdown-item>
            <b-dropdown-item href="#/cas/harga"
            :active="currRoute === 'cas-harga'">Harga SmartHR CAS</b-dropdown-item>
          </b-nav-item-dropdown> -->
          <b-nav-item href="#/about" :active="currRoute === 'about'">Tentang</b-nav-item>
          <b-nav-item href="#footer" :active="currRoute === 'kontak'">Kontak</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
export default {
  computed: {
    currRoute() {
      return this.$route.name || 'home'
    },
  },
}
</script>
