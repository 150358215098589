import Vue from 'vue'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/smart-hr.css'
import '@/plugins/bootstrapVue'
import id from 'vee-validate/dist/locale/id'
import VeeValidate, { Validator } from 'vee-validate'
import VueAnalytics from 'vue-analytics'
import App from './App.vue'
import router from './router'
import httpPlugin from './plugins/http'
import eventbus from './plugins/eventbus'

// Localize takes the locale object as the second argument (optional) and merges it.
Validator.localize('id', id)
Vue.use(VeeValidate)
Vue.use(eventbus)
Vue.use(httpPlugin)

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueAnalytics, {
    id: 'UA-41567016-12',
    router,
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
