<template>
  <div id="app">
    <app-topheader></app-topheader>
    <router-view />
    <app-portofolio></app-portofolio>
    <app-footer></app-footer>
    <b-modal
      ref="modalError"
      title="Peringatan!"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
      <div>
        <p>{{ errorMsg }}</p>
      </div>
      <div slot="modal-footer" class="w-100">
        <button type="button" class="btn float-right btn-primary btn-sm" @click="hideModal('modalError')">
          Tutup
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import AppTopheader from '@/components/AppTopheader.vue'
import AppPortofolio from '@/components/AppPortofolio.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'app',
  components: {
    AppTopheader,
    AppPortofolio,
    AppFooter,
  },
  data() {
    return {
      headerBgVariant: 'danger',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'danger',
      footerTextVariant: 'light',
      errorMsg: '',
    }
  },
  mounted() {
    this.$bus.$on('showModal', (payload) => this.showModal(payload))
    this.$bus.$on('hideModal', (ref) => this.hideModal(ref))
  },
  methods: {
    showModal(payload) {
      this.errorMsg = payload.msg
      this.$refs[payload.ref].show()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
  },
}
</script>
