import axios from 'axios'

// allow use http client without Vue instance
export const http = axios.create({
  baseURL: '/',
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

// before a request is made start the nprogress
http.interceptors.request.use((config) => {
  // eslint-disable-next-line
  NProgress.start();
  return config
})

// before a response is returned stop nprogress
http.interceptors.response.use((response) => {
  // eslint-disable-next-line
  NProgress.done();
  return response
})

// receive store and data by options
// https://vuejs.org/v2/guide/plugins.html
export default function install(Vue) {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return http
    },
  })
}
