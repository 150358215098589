import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    {
      path: '/cas',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "personalia" */ '../views/Cas.vue'),
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /personalia/index is matched
          path: '',
          name: 'cas-index',
          component: () => import(/* webpackChunkName: "personalia" */ '../views/cas/Index.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /personalia/harga is matched
          path: 'harga',
          name: 'cas-harga',
          component: () => import(/* webpackChunkName: "personalia" */ '../views/cas/Harga.vue'),
        },
      ],
    },
    {
      path: '/personalia',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "personalia" */ '../views/Personalia.vue'),
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /personalia/index is matched
          path: '',
          name: 'personalia-index',
          component: () => import(/* webpackChunkName: "personalia" */ '../views/personalia/Index.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /personalia/fitur is matched
          path: 'fitur',
          name: 'personalia-fitur',
          component: () => import(/* webpackChunkName: "personalia" */ '../views/personalia/Fitur.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /personalia/harga is matched
          path: 'harga',
          name: 'personalia-harga',
          component: () => import(/* webpackChunkName: "personalia" */ '../views/personalia/Harga.vue'),
        },
      ],
    },
    {
      path: '/dasbordiklat',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "dasbordiklat" */ '../views/Dasbordiklat.vue'),
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /dasbordiklat/index is matched
          path: '',
          name: 'dasbordiklat-index',
          component: () => import(/* webpackChunkName: "dasbordiklat" */ '../views/dasbordiklat/Index.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /dasbordiklat/fitur is matched
          path: 'fitur',
          name: 'dasbordiklat-fitur',
          component: () => import(/* webpackChunkName: "dasbordiklat" */ '../views/dasbordiklat/Fitur.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /dasbordiklat/harga is matched
          path: 'harga',
          name: 'dasbordiklat-harga',
          component: () => import(/* webpackChunkName: "dasbordiklat" */ '../views/dasbordiklat/Harga.vue'),
        },
      ],
    },
    {
      path: '/simdiklat',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "simdiklat" */ '../views/Simdiklat.vue'),
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /simdiklat/index is matched
          path: '',
          name: 'simdiklat-index',
          component: () => import(/* webpackChunkName: "simdiklat" */ '../views/simdiklat/Index.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /simdiklat/fitur is matched
          path: 'fitur',
          name: 'simdiklat-fitur',
          component: () => import(/* webpackChunkName: "simdiklat" */ '../views/simdiklat/Fitur.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /simdiklat/harga is matched
          path: 'harga',
          name: 'simdiklat-harga',
          component: () => import(/* webpackChunkName: "simdiklat" */ '../views/simdiklat/Harga.vue'),
        },
      ],
    },
    {
      path: '/lmsdiklat',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "lmsdiklat" */ '../views/Lmsdiklat.vue'),
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /lmsdiklat/index is matched
          path: '',
          name: 'lmsdiklat-index',
          component: () => import(/* webpackChunkName: "lmsdiklat" */ '../views/lmsdiklat/Index.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /lmsdiklat/fitur is matched
          path: 'fitur',
          name: 'lmsdiklat-fitur',
          component: () => import(/* webpackChunkName: "lmsdiklat" */ '../views/lmsdiklat/Fitur.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /lmsdiklat/harga is matched
          path: 'harga',
          name: 'lmsdiklat-harga',
          component: () => import(/* webpackChunkName: "lmsdiklat" */ '../views/lmsdiklat/Harga.vue'),
        },
      ],
    },
    {
      path: '/smartmonev',
      component: () => import(/* webpackChunkName: "smartmonev" */ '../views/Smartmonev.vue'),
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /smartmonev/index is matched
          path: '',
          name: 'smartmonev-index',
          component: () => import(/* webpackChunkName: "smartmonev" */ '../views/smartmonev/Index.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /smartmonev/fitur is matched
          path: 'fitur',
          name: 'smartmonev-fitur',
          component: () => import(/* webpackChunkName: "smartmonev" */ '../views/smartmonev/Fitur.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /smartmonev/harga is matched
          path: 'harga',
          name: 'smartmonev-harga',
          component: () => import(/* webpackChunkName: "smartmonev" */ '../views/elearning/Harga.vue'),
        },
      ],
    },
    {
      path: '/liveagent',
      component: () => import(/* webpackChunkName: "liveagent" */ '../views/Liveagent.vue'),
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /liveagent/index is matched
          path: '',
          name: 'liveagent-index',
          component: () => import(/* webpackChunkName: "liveagent" */ '../views/liveagent/Index.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /liveagent/fitur is matched
          path: 'fitur',
          name: 'liveagent-fitur',
          component: () => import(/* webpackChunkName: "liveagent" */ '../views/liveagent/Fitur.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /liveagent/harga is matched
          path: 'harga',
          name: 'liveagent-harga',
          component: () => import(/* webpackChunkName: "liveagent" */ '../views/liveagent/Harga.vue'),
        },
      ],
    },
    {
      path: '/ppdb',
      component: () => import(/* webpackChunkName: "ppdb" */ '../views/Ppdb.vue'),
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /ppdb/index is matched
          path: '',
          name: 'ppdb-index',
          component: () => import(/* webpackChunkName: "ppdb" */ '../views/ppdb/Index.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /ppdb/fitur is matched
          path: 'fitur',
          name: 'ppdb-fitur',
          component: () => import(/* webpackChunkName: "ppdb" */ '../views/ppdb/Fitur.vue'),
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /ppdb/harga is matched
          path: 'harga',
          name: 'ppdb-harga',
          component: () => import(/* webpackChunkName: "ppdb" */ '../views/ppdb/Harga.vue'),
        },
      ],
    },
    {
      path: '/buy/:id',
      name: 'buy',
      component: () => import(/* webpackChunkName: "buy" */ '../views/Buy.vue'),
    },
    {
      path: '/demo/:id',
      name: 'demo',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Demo.vue'),
    },
    {
      path: '/thanks',
      name: 'thanks',
      component: () => import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue'),
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
      path: '/kontak',
      name: 'kontak',
      component: () => import(/* webpackChunkName: "kontak" */ '../views/Kontak.vue'),
    },
    {
      path: '/kebijakan',
      name: 'kebijakan',
      component: () => import(/* webpackChunkName: "kebijakan" */ '../views/Kebijakan.vue'),
    },
  ],
})

/* eslint-disable no-undef */
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
  window.scrollTo(0, 0)
})

export default router
